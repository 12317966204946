@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Edu+QLD+Beginner:wght@700&family=Pacifico&family=The+Nautigal:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+QLD+Beginner:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Edu+QLD+Beginner:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Edu+QLD+Beginner:wght@700&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@300&family=The+Nautigal:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Raleway:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tinos&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

@layer components {
	.Momo {
		@apply text-[#a50064] font-bold text-xl leading-3;
	}
	.TPBank {
		@apply text-[#5e2e86] font-bold text-xl leading-3;
	}
	.VPBank {
		@apply text-[#00b74f] font-bold text-xl leading-3;
	}
	.ACB {
		@apply text-[#1348ab] font-bold text-xl leading-3;
	}
	.Agribank {
		@apply text-[#ae1c3f] font-bold text-xl leading-3;
	}
	.Vietcombank {
		@apply text-[#144633] font-bold text-[18px] leading-3;
	}
}

@layer base {
	html {
		font-family: 'Open Sans', sans-serif;
		font-weight: normal;
		font-style: normal;
		color: 'black';
		overflow-x: hidden;
		scroll-behavior: smooth;
	}
	h1 {
		font-size: 48px;
		font-weight: bold;
	}

	h2 {
		font-size: 36px;
		font-weight: bold;
	}

	h3 {
		font-size: 28px;
		font-weight: bold;
	}

	h4 {
		font-size: 22px;
		font-weight: bold;
	}

	h5 {
		font-size: 18px;
		font-weight: bold;
	}

	/* p {
        font-size: 16px;
    } */
	::-webkit-scrollbar {
		width: 10px;
		height: 20px;
	}
	::-webkit-scrollbar-track {
		border-radius: 100vh;
		background: #f7f4ed;
	}
	::-webkit-scrollbar-thumb {
		background: #e0cbcb;
		border-radius: 100vh;
		border: 3px solid #f6f7ed;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #c0a0b9;
	}
}

@layer utilities {
	/* hidden arrows input number */
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		@apply appearance-none;
	}
	.scrollbar::-webkit-scrollbar {
		width: 10px;
		height: 20px;
	}

	.scrollbar::-webkit-scrollbar-track {
		border-radius: 100vh;
		background: #f7f4ed;
	}

	.scrollbar::-webkit-scrollbar-thumb {
		background: #e0cbcb;
		border-radius: 100vh;
		border: 3px solid #f6f7ed;
	}

	.scrollbar::-webkit-scrollbar-thumb:hover {
		background: #c0a0b9;
	}

	.scrollbar-hidden::-webkit-scrollbar {
		display: none;
	}
}

.box-qr {
	--b: 2px;
	/* thickness of the border */
	--c: black;
	/* color of the border */
	--w: 20px;
	/* width of border */

	border: var(--b) solid transparent;
	/* space for the border */
	--g: #0000 90deg, var(--c) 0;
	background: conic-gradient(
				from 90deg at top var(--b) left var(--b),
				var(--g)
			)
			0 0,
		conic-gradient(
				from 180deg at top var(--b) right var(--b),
				var(--g)
			)
			100% 0,
		conic-gradient(
				from 0deg at bottom var(--b) left var(--b),
				var(--g)
			)
			0 100%,
		conic-gradient(
				from -90deg at bottom var(--b) right var(--b),
				var(--g)
			)
			100% 100%;
	background-size: var(--w) var(--w);
	background-origin: border-box;
	background-repeat: no-repeat;

	/*Irrelevant code*/
	/* width: 200px;
    height: 100px; */
	box-sizing: border-box;
	/* margin: 5px; */
	/* display: inline-flex; */
	/* font-size: 30px; */
	justify-content: center;
	align-items: center;
	/* line-height: 90px; */
}
.ReactCrop__image {
	height: 300px;
}
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background-color: #fff;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 /50%);
	box-shadow: inset 0 0 6px #00000080;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
	box-shadow: inset 0 0 6px #0000004d;
}

.gradient {
	background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
@keyframes loader {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		transform: scale(1);
		opacity: 0;
	}
}

.show {
	visibility: visible;
}

.accordion-button::after {
	background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkFDQzE1IiBoZWlnaHQ9IjIwMHB4IiB3aWR0aD0iMjAwcHgiIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9Ii0zMyAtMzMgMzk2LjAwIDM5Ni4wMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgc3Ryb2tlPSIjRkFDQzE1Ij48ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCI+PC9nPjxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggaWQ9IlhNTElEXzIyNV8iIGQ9Ik0zMjUuNjA3LDc5LjM5M2MtNS44NTctNS44NTctMTUuMzU1LTUuODU4LTIxLjIxMywwLjAwMWwtMTM5LjM5LDEzOS4zOTNMMjUuNjA3LDc5LjM5MyBjLTUuODU3LTUuODU3LTE1LjM1NS01Ljg1OC0yMS4yMTMsMC4wMDFjLTUuODU4LDUuODU4LTUuODU4LDE1LjM1NSwwLDIxLjIxM2wxNTAuMDA0LDE1MGMyLjgxMywyLjgxMyw2LjYyOCw0LjM5MywxMC42MDYsNC4zOTMgczcuNzk0LTEuNTgxLDEwLjYwNi00LjM5NGwxNDkuOTk2LTE1MEMzMzEuNDY1LDk0Ljc0OSwzMzEuNDY1LDg1LjI1MSwzMjUuNjA3LDc5LjM5M3oiPjwvcGF0aD4gPC9nPjwvc3ZnPg==');
}
.accordion-button:not(.collapsed) {
	background-color: unset;
}
.accordion-button:not(.collapsed)::after {
	background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjRkFDQzE1IiBoZWlnaHQ9IjIwMHB4IiB3aWR0aD0iMjAwcHgiIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9Ii0zMyAtMzMgMzk2LjAwIDM5Ni4wMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgc3Ryb2tlPSIjRkFDQzE1Ij48ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCI+PC9nPjxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggaWQ9IlhNTElEXzIyNV8iIGQ9Ik0zMjUuNjA3LDc5LjM5M2MtNS44NTctNS44NTctMTUuMzU1LTUuODU4LTIxLjIxMywwLjAwMWwtMTM5LjM5LDEzOS4zOTNMMjUuNjA3LDc5LjM5MyBjLTUuODU3LTUuODU3LTE1LjM1NS01Ljg1OC0yMS4yMTMsMC4wMDFjLTUuODU4LDUuODU4LTUuODU4LDE1LjM1NSwwLDIxLjIxM2wxNTAuMDA0LDE1MGMyLjgxMywyLjgxMyw2LjYyOCw0LjM5MywxMC42MDYsNC4zOTMgczcuNzk0LTEuNTgxLDEwLjYwNi00LjM5NGwxNDkuOTk2LTE1MEMzMzEuNDY1LDk0Ljc0OSwzMzEuNDY1LDg1LjI1MSwzMjUuNjA3LDc5LjM5M3oiPjwvcGF0aD4gPC9nPjwvc3ZnPg==');
}
input[type='date']::-webkit-calendar-picker-indicator {
	background-image: url('assets/images/calendar-days-white.svg');
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
input[type='time']::-webkit-calendar-picker-indicator {
	background-image: url('assets/images/clock-regular-white.svg');
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #eab308;
}
